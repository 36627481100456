import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { supabase } from "./lib/supabaseClient";

// Layout imports
import Sidebar from "./layout/Sidebar";

// Page imports
import Dashboard from "./pages/Dashboard";
import Markets from "./pages/Markets";
import Transactions from "./pages/Transactions";
import Profile from "./pages/Profile";
import Setting from "./pages/Setting";
import Help from "./pages/Help";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

// Provider imports
import { PriceProvider } from "./data/currencyData";
import { ProfileProvider } from "./context/ProfileContext";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
    } catch (error) {
      console.error('Error checking auth status:', error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/signin" element={<SignIn onSignIn={() => setIsAuthenticated(true)} />} />
        <Route path="/signup" element={<SignUp onSignUp={() => setIsAuthenticated(true)} />} />
        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Routes>
    );
  }

  return (
    <ProfileProvider>
      <PriceProvider>
        <div className="min-h-screen bg-[#F0F8FF] dark:bg-[#12131A]">
          <Sidebar />
          <main className="md:ml-[19.2rem]">
            <div className="container mx-auto px-[34px] py-[28px]">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/markets" element={<Markets />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/setting" element={<Setting />} />
                <Route path="/help" element={<Help />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </main>
        </div>
      </PriceProvider>
    </ProfileProvider>
  );
}

export default App;