import React, { useState, useEffect } from 'react';
import { useProfile } from '../context/ProfileContext';
import { supabase } from '../lib/supabaseClient';

function Profile() {
  const { profileData, setProfileData, loading: contextLoading } = useProfile();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    isEmailVerified: false,
    profilePicture: null
  });

  // Initialize form data when profileData changes
  useEffect(() => {
    if (profileData) {
      setFormData({
        username: profileData.username || '',
        password: profileData.password || '',
        email: profileData.email || '',
        isEmailVerified: profileData.isEmailVerified || false,
        profilePicture: profileData.profilePicture || null
      });
    }
  }, [profileData]);

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          profilePicture: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      // Update profile in Supabase
      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          username: formData.username,
          updated_at: new Date()
        })
        .eq('id', user.id);

      if (updateError) throw updateError;

      // Update local context
      setProfileData({
        ...formData
      });

      console.log('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleVerifyEmail = async () => {
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: formData.email
      });
      
      if (error) throw error;
      
      setFormData(prev => ({
        ...prev,
        isEmailVerified: true
      }));
      console.log('Verification email sent to:', formData.email);
    } catch (error) {
      console.error('Error sending verification email:', error.message);
    }
  };

  if (contextLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-lg">Loading profile...</div>
      </div>
    );
  }

  return (
    <div className="py-[28px] px-4 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Profile</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex items-center space-x-4">
          <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-200">
            {formData.profilePicture ? (
              <img src={formData.profilePicture} alt="Profile" className="w-full h-full object-cover" />
            ) : (
              <div className="w-full h-full flex items-center justify-center text-gray-500 text-2xl font-bold">
                {formData.username ? formData.username.charAt(0).toUpperCase() : 'U'}
              </div>
            )}
          </div>
          <div>
            <label htmlFor="profile-picture" className="block mb-2 font-medium text-gray-700">Profile Picture</label>
            <input
              id="profile-picture"
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />
          </div>
        </div>

        <div>
          <label htmlFor="username" className="block mb-2 font-medium text-gray-700">Username</label>
          <input
            id="username"
            name="username"
            type="text"
            value={formData.username}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="password" className="block mb-2 font-medium text-gray-700">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="email" className="block mb-2 font-medium text-gray-700">Email Address</label>
          <div className="flex space-x-2">
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              type="button"
              onClick={handleVerifyEmail}
              disabled={formData.isEmailVerified}
              className={`px-4 py-2 rounded-md text-white font-medium ${
                formData.isEmailVerified
                  ? 'bg-green-500 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {formData.isEmailVerified ? 'Verified' : 'Verify'}
            </button>
          </div>
          {formData.isEmailVerified && (
            <p className="text-green-500 mt-1">Email verified</p>
          )}
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          {loading ? 'Updating...' : 'Update Profile'}
        </button>
      </form>
    </div>
  );
}

export default Profile;