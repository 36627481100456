import React from 'react';

const TradingViewWidget = () => {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (window.TradingView && containerRef.current) {
        new window.TradingView.widget({
          autosize: true,
          symbol: "PYTH:BTCUSD",
          interval: "1",
          timezone: "Etc/UTC",
          theme: "light",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview-widget"
        });
      }
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="h-screen bg-white dark:bg-[#131313] p-6">
      <div className="h-[calc(100vh-100px)]">
        <div 
          id="tradingview-widget" 
          ref={containerRef}
          className="h-full w-full"
        />
      </div>
    </div>
  );
};

export default TradingViewWidget;