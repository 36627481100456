import { createContext, useState, useContext, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';

const ProfileContext = createContext();

export function ProfileProvider({ children }) {
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    profilePicture: null,
    isEmailVerified: false
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;

      if (data) {
        setProfile({
          username: data.username || '',
          email: data.email || '',
          profilePicture: data.profile_picture || null,
          isEmailVerified: data.is_email_verified || false
        });
      }
    } catch (error) {
      console.error('Error fetching profile:', error.message);
    }
  };

  const updateProfileContext = (newProfileData) => {
    setProfile(newProfileData);
  };

  return (
    <ProfileContext.Provider value={{ profile, updateProfileContext }}>
      {children}
    </ProfileContext.Provider>
  );
}

export const useProfile = () => useContext(ProfileContext);