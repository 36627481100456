import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import { useWalletBalance } from '../hooks/useWalletBalance';

Chart.register(...registerables);

const generateTimeLabels = (timeframe) => {
  const now = new Date();
  const labels = [];
  const intervals = {
    '1H': { interval: 5, count: 12 },
    '3H': { interval: 15, count: 12 },
    '5H': { interval: 25, count: 12 },
    '1D': { interval: 120, count: 12 },
    '1W': { interval: 24 * 60, count: 7 },
    '1M': { interval: 24 * 60 * 2, count: 15 },
  };

  const { interval, count } = intervals[timeframe] || intervals['1H'];

  for (let i = count - 1; i >= 0; i--) {
    const date = new Date(now - i * interval * 60000);
    labels.unshift(
      timeframe === '1W' || timeframe === '1M'
        ? date.toLocaleDateString()
        : date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    );
  }

  return labels;
};

const simulateHistoricalData = (currentBalance, timeframe, volatility = 0.002) => {
  const count = timeframe === '1W' ? 7 : timeframe === '1M' ? 15 : 12;
  const trend = Math.random() > 0.5 ? 1 : -1;
  let lastValue = currentBalance;
  return Array.from({ length: count }, (_, i) => {
    const randomWalk = (Math.random() - 0.5) * 2 * volatility;
    const trendComponent = (trend * i * volatility) / count;
    const newValue = lastValue * (1 + randomWalk + trendComponent);
    lastValue = i === count - 1 ? currentBalance : newValue;
    return lastValue;
  });
};

const ChartLine = () => {
  const chartRef = useRef(null);
  const { balance, loading } = useWalletBalance();
  const [timeframe, setTimeframe] = useState('1H');
  const chartInstance = useRef(null);

  const timeframes = useMemo(() => ['1H', '3H', '5H', '1D', '1W', '1M'], []);

  const handleTimeframeChange = (tf) => setTimeframe(tf);

  useEffect(() => {
    if (!chartRef.current || loading) return;

    const ctx = chartRef.current.getContext('2d');
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const labels = generateTimeLabels(timeframe);
    const historicalData = simulateHistoricalData(balance, timeframe);

    const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
    gradientFill.addColorStop(0, 'rgba(0, 96, 255, 0.2)');
    gradientFill.addColorStop(1, 'rgba(0, 96, 255, 0)');

    const config = {
      type: 'line',
      data: {
        labels,
        datasets: [{
          label: 'Balance (BTC)',
          data: historicalData,
          backgroundColor: gradientFill,
          borderColor: '#0060FF',
          fill: true,
          tension: 0.4,
          pointRadius: 0,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: '#0060FF',
          pointHoverBorderColor: '#fff',
          pointHoverBorderWidth: 2,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            titleColor: '#000',
            bodyColor: '#000',
            borderColor: '#0060FF',
            borderWidth: 1,
            padding: 10,
            callbacks: {
              label: (context) => `${context.parsed.y.toFixed(8)} BTC`
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'BTC',
              color: '#626D7D'
            },
            grid: {
              color: 'rgba(98, 109, 125, 0.1)'
            },
            ticks: {
              color: '#626D7D',
              callback: (value) => value.toFixed(8)
            }
          },
          x: {
            grid: { display: false },
            ticks: { color: '#626D7D' }
          }
        }
      }
    };

    chartInstance.current = new Chart(ctx, config);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [balance, timeframe, loading]);

  return (
    <div className="p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg mb-4 md:mb-0">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-white">My Vault</h2>
          <p className="text-xl text-blue-600 dark:text-blue-400 font-semibold">
            {loading ? '...' : `${balance.toFixed(8)} BTC`}
          </p>
        </div>
        <div className="flex gap-2">
          {timeframes.map((tf) => (
            <button
              key={tf}
              onClick={() => handleTimeframeChange(tf)}
              className={`px-3 py-1 rounded-full text-sm font-medium transition-colors ${
                timeframe === tf 
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600'
              }`}
            >
              {tf}
            </button>
          ))}
        </div>
      </div>
      <div className="h-[400px]">
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
};

export default ChartLine;

