import React, { useState, useEffect, useCallback } from "react";
import SelectField from "./SelectField";
import CustomBtn from "./CustomBtn";

function ConverterCard() {
  const [amount, setAmount] = useState("");
  const [convertedAmount, setConvertedAmount] = useState("");
  const [fromCurrency, setFromCurrency] = useState("USDT");
  const [toCurrency, setToCurrency] = useState("BTC");
  const [prices, setPrices] = useState({
    bitcoin: { usd: 0 },
    ethereum: { usd: 0 },
    solana: { usd: 0 },
    tether: { usd: 1 }
  });

  const supportedCurrencies = {
    BTC: "bitcoin",
    ETH: "ethereum",
    SOL: "solana",
    USDT: "tether"
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,solana,tether&vs_currencies=usd'
        );
        const data = await response.json();
        setPrices(data);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchPrices();
    const interval = setInterval(fetchPrices, 30000);
    return () => clearInterval(interval);
  }, []);

  const getRate = useCallback((currency) => {
    const coinId = supportedCurrencies[currency];
    return prices[coinId]?.usd || 0;
  }, [prices, supportedCurrencies]);

  const handleConvert = useCallback(() => {
    if (!amount || amount <= 0) {
      setConvertedAmount("");
      return;
    }

    const fromRate = getRate(fromCurrency);
    const toRate = getRate(toCurrency);

    if (fromRate === 0 || toRate === 0) {
      console.error("Invalid rates:", { fromRate, toRate });
      return;
    }

    // Convert everything to USD first, then to target currency
    const amountInUsd = parseFloat(amount) * fromRate;
    const result = amountInUsd / toRate;

    console.log('Conversion details:', {
      amount,
      fromCurrency,
      toCurrency,
      fromRate,
      toRate,
      amountInUsd,
      result
    });

    setConvertedAmount(result.toFixed(8));
  }, [amount, fromCurrency, toCurrency, getRate]);

  // Handle currency selection changes
  const handleFromCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    if (newCurrency === toCurrency) {
      // Swap currencies if same currency is selected
      setToCurrency(fromCurrency);
    }
    setFromCurrency(newCurrency);
  };

  const handleToCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    if (newCurrency === fromCurrency) {
      // Swap currencies if same currency is selected
      setFromCurrency(toCurrency);
    }
    setToCurrency(newCurrency);
  };

  useEffect(() => {
    handleConvert();
  }, [handleConvert]);

  const Currency = [
    { value: "USDT", label: "USDT" },
    { value: "BTC", label: "BTC" },
    { value: "ETH", label: "ETH" },
    { value: "SOL", label: "SOL" }
  ];

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-[#000000] dark:text-[#FFF] text-[20px] font-semibold leading-[28px]">
          Convert
        </p>
      </div>
      <div className="mt-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="relative border dark:border-[#292A32] border-[#E7EAEB] rounded-[7px]">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="0"
              className="w-full sm:w-[308px] text-[15px] font-normal leading-[10px] py-[15px] pl-[17px] pr-[6.5rem] text-[#626D7D] dark:text-white rounded-[7px] outline-none bg-gray-50 dark:bg-[#1C1C25] focus:bg-white focus:border-[#2F80ED]"
            />
            <div className="absolute right-[7px] top-[6px] bg-[#C7E4DB] rounded-[7px] px-2 w-[86px]">
              <SelectField 
                data={Currency} 
                textColor="text-[#26AB5F]"
                value={fromCurrency}
                onChange={handleFromCurrencyChange}
              />
            </div>
          </div>

          <div className="relative mt-[21px] border dark:border-[#292A32] border-[#E7EAEB] rounded-[7px]">
            <input
              type="text"
              value={convertedAmount}
              readOnly
              placeholder="0"
              className="w-full sm:w-[308px] text-[15px] font-normal leading-[10px] py-[15px] pl-[17px] pr-[6.5rem] rounded-[7px] text-[##626D7D] dark:text-white outline-none bg-gray-50 dark:bg-[#1C1C25]"
            />
            <div className="absolute right-[7px] top-[6px] bg-[#FCF4DB] rounded-[7px] px-2 w-[86px]">
              <SelectField 
                data={Currency} 
                textColor="text-[#FF7C04]"
                value={toCurrency}
                onChange={handleToCurrencyChange}
              />
            </div>
          </div>

          <div>
            <div className="text-center mt-[14px]">
              <CustomBtn
                onClick={handleConvert}
                bgColor="#4D91FF"
                textColor="#FFF"
                label="Convert Now"
                Width="100%"
              />
            </div>
            <p className="text-[#626D7D] dark:text-[#626D7D] text-[11px] font-normal leading-[11px] mt-[12px] sm:w-[290px] word-break">
              Price data refreshes every 30 seconds.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ConverterCard;