import { useState, useEffect } from 'react';

const WALLET_ADDRESS = 'bc1q5xs7d2nqdrknw9qmauc6jr2rtn2nemm4xkcuyn';

const useWalletTransactions = () => {
  const [transactionsData, setTransactionsData] = useState({
    transactions: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          `https://blockchain.info/rawaddr/${WALLET_ADDRESS}?cors=true`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }

        const data = await response.json();

        const formattedTransactions = data.txs.map(tx => {
          const isReceiving = tx.out.some(output => 
            output.addr === WALLET_ADDRESS
          );
          
          const amount = isReceiving
            ? tx.out.filter(output => output.addr === WALLET_ADDRESS)
                .reduce((sum, output) => sum + output.value, 0) / 100000000
            : tx.inputs.filter(input => input.prev_out.addr === WALLET_ADDRESS)
                .reduce((sum, input) => sum + input.prev_out.value, 0) / 100000000;

          const counterparty = isReceiving
            ? tx.inputs[0]?.prev_out?.addr || 'Unknown'
            : tx.out[0]?.addr || 'Unknown';

          const txTime = new Date(tx.time * 1000);
          const timeAgo = Math.floor((Date.now() - txTime) / (1000 * 60 * 60 * 24));
          const timeDisplay = timeAgo === 0 
            ? 'Today'
            : timeAgo === 1 
              ? 'Yesterday'
              : `${timeAgo} days ago`;

          return {
            signature: tx.hash,
            time: timeDisplay,
            action: isReceiving ? 'RECEIVE' : 'SEND',
            from: isReceiving ? counterparty : WALLET_ADDRESS,
            to: isReceiving ? WALLET_ADDRESS : counterparty,
            amount: amount.toLocaleString(undefined, { 
              minimumFractionDigits: 8,
              maximumFractionDigits: 8 
            }),
            token: 'BTC',
            value: `$${(amount * 42000).toLocaleString(undefined, { 
              minimumFractionDigits: 2,
              maximumFractionDigits: 2 
            })}`
          };
        });

        setTransactionsData({
          transactions: formattedTransactions,
          loading: false,
          error: null
        });
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setTransactionsData({
          transactions: [],
          loading: false,
          error: err.message
        });
      }
    };

    fetchTransactions();
    const interval = setInterval(fetchTransactions, 300000);

    return () => clearInterval(interval);
  }, []);

  return transactionsData;
};

export default useWalletTransactions;