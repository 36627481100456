import { Doughnut } from 'react-chartjs-2';
import { useWalletBalance } from '../hooks/useWalletBalance';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend, Title);

function DoughnutChart() {
  const { balance, loading, error } = useWalletBalance();

  const data = {
    labels: ['BTC', 'Others'],
    datasets: [
      {
        label: 'Wallet Distribution',
        data: [balance, 0], // Since this is a BTC-only wallet
        backgroundColor: [
          '#4D91FF',
          'rgba(153,102,255,1)'
        ],
        borderColor: [
          '#4D91FF',
          'rgba(153,102,255,1)'
        ],
        borderWidth: 1,
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
      },
      title: {
        display: true,
        text: 'Asset Distribution',
        color: '#4D91FF',
        font: {
          size: 24
        },
        padding: {
          top: 30,
          bottom: 30
        },
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-lg text-gray-600">Loading wallet balance...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-lg text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-md mx-auto p-4">
      <Doughnut data={data} options={options} />
      <div className="text-center mt-4">
        <p className="text-sm text-gray-600">
          Total BTC Balance: {balance.toFixed(8)} BTC
        </p>
      </div>
    </div>
  );
}

export default DoughnutChart;