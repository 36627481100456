import React, { useState } from 'react'

function Setting() {
  const [darkMode, setDarkMode] = useState(false)
  const [emailNotifications, setEmailNotifications] = useState(true)
  const [pushNotifications, setPushNotifications] = useState(true)
  const [language, setLanguage] = useState('en')
  const [twoFactorAuth, setTwoFactorAuth] = useState(false)

  const handleSaveSettings = (e) => {
    e.preventDefault()
    // Here you would typically save the settings to your backend
    console.log('Settings saved:', {
      darkMode,
      emailNotifications,
      pushNotifications,
      language,
      twoFactorAuth
    })
  }

  return (
    <div className="py-[28px] px-4 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Settings</h1>
      <form onSubmit={handleSaveSettings} className="space-y-6">
        <div>
          <h2 className="text-xl font-semibold mb-4">Account Settings</h2>
          <div className="space-y-4">
            <div>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={twoFactorAuth}
                  onChange={(e) => setTwoFactorAuth(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span>Enable Two-Factor Authentication</span>
              </label>
            </div>
            <div>
              <label htmlFor="language" className="block mb-2 font-medium">Language</label>
              <select
                id="language"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">Notifications</h2>
          <div className="space-y-4">
            <div>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={emailNotifications}
                  onChange={(e) => setEmailNotifications(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span>Email Notifications</span>
              </label>
            </div>
            <div>
              <label className="flex items-center space-x-2 cursor-pointer">
                <input
                  type="checkbox"
                  checked={pushNotifications}
                  onChange={(e) => setPushNotifications(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span>Push Notifications</span>
              </label>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">Appearance</h2>
          <div>
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={darkMode}
                onChange={(e) => setDarkMode(e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span>Dark Mode</span>
            </label>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">Privacy</h2>
          <p className="text-sm text-gray-600 mb-2">
            Review our <a href="#" className="text-blue-500 hover:underline">Privacy Policy</a> and <a href="#" className="text-blue-500 hover:underline">Terms of Service</a>.
          </p>
          <button
            type="button"
            className="text-red-500 hover:underline"
            onClick={() => console.log('Delete account clicked')}
          >
            Delete Account
          </button>
        </div>

        <div>
          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-500 text-white font-medium rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Save Settings
          </button>
        </div>
      </form>
    </div>
  )
}

export default Setting

