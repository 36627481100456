import React from 'react';
import useWalletTransactions from '../hooks/useWalletTransactions';
import { useWalletBalance } from '../hooks/useWalletBalance';

function Transactions() {
  const { transactions, loading: transactionsLoading, error: transactionsError } = useWalletTransactions();
  const { loading: balanceLoading } = useWalletBalance();

  if (transactionsLoading || balanceLoading) {
    return (
      <div className="py-[28px] px-4">
        <h2 className="text-2xl font-bold mb-4">Transactions</h2>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
        </div>
      </div>
    );
  }

  if (transactionsError) {
    return (
      <div className="py-[28px] px-4">
        <h2 className="text-2xl font-bold mb-4">Transactions</h2>
        <div className="text-red-500">Error loading transactions: {transactionsError}</div>
      </div>
    );
  }

  return (
    <div className="py-[28px] px-4">
      <h2 className="text-2xl font-bold mb-4">Transactions</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg overflow-hidden">
          <thead className="bg-gray-100 dark:bg-gray-700">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Signature</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Time</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Action</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">From</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">To</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Amount</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Token</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Value</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {transactions.map((transaction, index) => (
              <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                  {transaction.signature.slice(0, 8)}...{transaction.signature.slice(-8)}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{transaction.time}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{transaction.action}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  <div className="flex items-center">
                    <img src="/placeholder.svg?height=20&width=20" alt="From" className="w-5 h-5 rounded-full mr-2" />
                    {transaction.from.slice(0, 8)}...{transaction.from.slice(-8)}
                  </div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  <div className="flex items-center">
                    <img src="/placeholder.svg?height=20&width=20" alt="To" className="w-5 h-5 rounded-full mr-2" />
                    {transaction.to.slice(0, 8)}...{transaction.to.slice(-8)}
                  </div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{transaction.amount}</td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  <div className="flex items-center">
                    <img src="/placeholder.svg?height=20&width=20" alt="Token" className="w-5 h-5 rounded-full mr-2" />
                    {transaction.token}
                  </div>
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">{transaction.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Transactions;