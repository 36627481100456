import React, { useMemo } from "react";
import EyeIcon from '../assets/svg/Eye.svg';
import { usePriceData } from '../data/currencyData';
import { usePyth24Feed } from '../hooks/usePyth24Feed';
import { usePythPrices } from '../hooks/usePythPrices';
import { useWalletBalance } from '../hooks/useWalletBalance';

function BalanceCard({ data }) {
    const { 
        prices: livePrices, 
        loading: liveLoading 
    } = usePythPrices();
    const {
        baselinePrices,
        loading: baselineLoading,
        calculateDailyPNL
    } = usePyth24Feed();
    const {
        balance: btcBalance,
        loading: walletLoading,
        error: walletError
    } = useWalletBalance();

    const isEstimatedBalance = data.name === 'Estimated Balance';
    const isLoading = liveLoading || baselineLoading || walletLoading || !livePrices || !baselinePrices;

    const balanceData = useMemo(() => {
        if (isLoading) {
            return {
                balance: "Loading...",
                profit: "...",
                percentage: "...",
                cryptoAmount: isEstimatedBalance ? "Loading..." : null
            };
        }

        if (isEstimatedBalance) {
            const currentPrice = livePrices.BTCUSDT?.price 
                ? parseFloat(livePrices.BTCUSDT.price.replace(/[$,]/g, ''))
                : 0;
            const baselinePrice = baselinePrices.BTCUSDT?.price || 0;
            const estimatedBalance = currentPrice * btcBalance;
            const dailyPnlValue = (currentPrice - baselinePrice) * btcBalance;
            const dailyPnlPercentage = baselinePrice ? ((currentPrice - baselinePrice) / baselinePrice) * 100 : 0;

            return {
                balance: `$${estimatedBalance.toLocaleString(undefined, { 
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2 
                })}`,
                profit: `${dailyPnlValue >= 0 ? '+' : '-'}$${Math.abs(dailyPnlValue).toLocaleString(undefined, { 
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2 
                })}`,
                percentage: `${dailyPnlPercentage >= 0 ? '+' : ''}${dailyPnlPercentage.toFixed(2)}%`,
                cryptoAmount: `${btcBalance.toFixed(8)} BTC`
            };
        }

        const symbol = `${data.name.toUpperCase()}USDT`;
        const currentPrice = livePrices[symbol]?.price 
            ? parseFloat(livePrices[symbol].price.replace(/[$,]/g, ''))
            : 0;
        const baselinePrice = baselinePrices[symbol]?.price || 0;
        const dailyPnlPercentage = baselinePrice ? ((currentPrice - baselinePrice) / baselinePrice) * 100 : 0;
        const priceChange = currentPrice - baselinePrice;

        return {
            balance: livePrices[symbol]?.price || "Loading...",
            profit: `${priceChange >= 0 ? '+' : '-'}$${Math.abs(priceChange).toLocaleString(undefined, { 
                minimumFractionDigits: 2, 
                maximumFractionDigits: 2 
            })}`,
            percentage: `${dailyPnlPercentage >= 0 ? '+' : ''}${dailyPnlPercentage.toFixed(2)}%`
        };

    }, [livePrices, baselinePrices, btcBalance, data, isLoading, isEstimatedBalance]);

    const textColor = isLoading ? 'text-gray-400' : 
        balanceData.profit.startsWith('-') ? 'text-[#EB5757]' : 'text-[#219653]';

    const bgColor = isLoading ? 'bg-gray-100 text-gray-400' :
        balanceData.percentage.startsWith('-') ? 'text-[#EB5757] bg-[#eb575738]' : 'text-[#219653] bg-[#2196543c]';

    return (
        <div className="rounded-xl px-[20px] py-[22px] bg-[#FFF] dark:bg-[#1C1C25]">
            <div className="flex justify-between">
                <div className="flex gap-4">
                    <div className="w-[44px] h-[33px]">
                        <img
                            className={`object-cover w-full max-h-[43px] ${isLoading ? 'opacity-50' : ''}`}
                            src={data.icon}
                            alt={data.name}
                        />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-[#000] dark:text-white text-[16px] leading-[28px] font-semibold word-break mt-2">
                            {data.name}
                        </p>
                        {isEstimatedBalance && (
                            <p className="text-[#626D7D] text-[13px] leading-[22px] font-medium">
                                {balanceData.cryptoAmount}
                            </p>
                        )}
                    </div>
                </div>
                <div className="relative">
                    <img
                        className={`w-6 h-6 mt-[10px] mr-[3px] ${isLoading ? 'opacity-50' : ''}`}
                        src={EyeIcon}
                        alt="Toggle visibility"
                    />
                </div>
            </div>
            <div className="mt-[29px]">
                <p className="text-[#000] dark:text-white text-[30px] leading-[28px] font-semibold word-break">
                    {balanceData.balance}
                </p>
            </div>
            <div className="flex justify-between items-center mt-4">
                <p className="text-[#626D7D] text-[13px] leading-[22px] font-medium word-break">
                    Daily PNL
                </p>
                <p className={`text-[13px] leading-[22px] font-semibold word-break ${textColor}`}>
                    {balanceData.profit}
                </p>
                <span className={`rounded-[60px] px-2 text-[11px] leading-[22px] font-semibold word-break ${bgColor}`}>
                    {balanceData.percentage}
                </span>
            </div>
        </div>
    );
}

export default BalanceCard;