import React from "react";
import Table from "./Table";
import { usePriceData } from '../data/currencyData';
import Button from "./Button";

const Tabs = ({ color }) => {
  const [openTab, setOpenTab] = React.useState(1);
  const { currentRateData, loading, error } = usePriceData(); // Changed from currentRateDate to currentRateData

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[270px]">
        <p className="text-[#626D7D] dark:text-white">Loading market data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-[270px]">
        <p className="text-red-500">Error loading market data</p>
      </div>
    );
  }

  // Add default empty array if currentRateData is undefined
  const marketData = currentRateData || [];

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-14 sm:pt-[17px] pb-4 flex-row justify-end"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 text-center pb-3 sm:pb-0">
              <a
                className={
                  "px-[13.5px] py-1 rounded-[7px] block text-[10px] font-semibold leading-[22px] text-center " +
                  (openTab === 1
                    ? "text-bermuda dark:bg-[#131313] bg-silver"
                    : "text-[#000] dark:text-white bg-deepsilver dark:bg-[#131313]")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                All
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 text-center">
              <a
                className={
                  "px-[13.5px] py-1 rounded-[7px] block text-[10px] font-semibold leading-[22px] text-center " +
                  (openTab === 2
                    ? "text-bermuda dark:bg-[#131313] bg-silver"
                    : "text-[#000] dark:text-white bg-deepsilver dark:bg-[#131313]")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Metaverse
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 text-center">
              <a
                className={
                  "px-[13.5px] py-1 rounded-[7px] block text-[10px] font-semibold leading-[22px] text-center " +
                  (openTab === 3
                    ? "text-bermuda dark:bg-[#131313] bg-silver"
                    : "text-[#000] dark:text-white bg-deepsilver dark:bg-[#131313]")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Gaming
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 text-center">
              <a
                className={
                  "px-[13.5px] py-1 rounded-[7px] block text-[10px] font-semibold leading-[22px] text-center " +
                  (openTab === 4
                    ? "text-bermuda dark:bg-[#131313] bg-silver"
                    : "text-[#000] dark:text-white bg-deepsilver dark:bg-[#131313]")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link4"
                role="tablist"
              >
                Defi
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 text-center">
              <a
                className={
                  "px-[13.5px] py-1 rounded-[7px] block text-[10px] font-semibold leading-[22px] text-center " +
                  (openTab === 5
                    ? "text-bermuda dark:bg-[#131313] bg-silver"
                    : "text-[#000] dark:text-white bg-deepsilver dark:bg-[#131313]")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(5);
                }}
                data-toggle="tab"
                href="#link5"
                role="tablist"
              >
                NFT
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col break-words bg-white dark:bg-[#1C1C25]">
            <div className="mt-[13px]">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <div className='xl:w-[419px] h-[270px] overflow-auto custom-scrollbar pr-[19px]'>
                    <Table data={marketData} />
                  </div>
                  <div className="float-right pr-6 mt-[13px]">
                    <Button />
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div className='xl:w-[419px] h-[270px] overflow-auto custom-scrollbar pr-[19px]'>
                    <Table data={marketData.filter(item => item.category === 'metaverse')} />
                  </div>
                  <div className="float-right pr-6 mt-[13px]">
                    <Button />
                  </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <div className='xl:w-[419px] h-[270px] overflow-auto custom-scrollbar pr-[19px]'>
                    <Table data={marketData.filter(item => item.category === 'gaming')} />
                  </div>
                  <div className="float-right pr-6 mt-[13px]">
                    <Button />
                  </div>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <div className='xl:w-[419px] h-[270px] overflow-auto custom-scrollbar pr-[19px]'>
                    <Table data={marketData.filter(item => item.category === 'defi')} />
                  </div>
                  <div className="float-right pr-6 mt-[13px]">
                    <Button />
                  </div>
                </div>
                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                  <div className='xl:w-[419px] h-[270px] overflow-auto custom-scrollbar pr-[19px]'>
                    <Table data={marketData.filter(item => item.category === 'nft')} />
                  </div>
                  <div className="float-right pr-6 mt-[13px]">
                    <Button />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
      <Tabs />
    </>
  );
}