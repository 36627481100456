import { useState, useEffect, useRef } from 'react';
import { PriceServiceConnection } from '@pythnetwork/price-service-client';

const PRICE_IDS = {
  'BTCUSDT': '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
  'ETHUSDT': '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
  'SOLUSDT': '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d'
};

export const usePyth24Feed = () => {
  const [baselinePrices, setBaselinePrices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const lastUpdateRef = useRef(null);
  const connectionRef = useRef(null);

  const formatPrice = (price) => {
    return Number(price).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const calculateDailyPNL = (currentPrice, baselinePrice) => {
    if (!baselinePrice) return 0;
    return ((currentPrice - baselinePrice) / baselinePrice) * 100;
  };

  const updateBaseline = async () => {
    try {
      if (!connectionRef.current) {
        throw new Error('No connection established');
      }

      const priceIds = Object.values(PRICE_IDS);
      const priceFeeds = await connectionRef.current.getLatestPriceFeeds(priceIds);

      const newBaselines = {};
      priceFeeds.forEach(feed => {
        const symbol = Object.entries(PRICE_IDS).find(([_, id]) => 
          id.indexOf(feed.id) !== -1
        )?.[0];

        if (symbol && feed.price) {
          const price = Number(feed.price.price) * Math.pow(10, feed.price.expo);
          newBaselines[symbol] = {
            price,
            formattedPrice: formatPrice(price),
            timestamp: Date.now()
          };
        }
      });

      setBaselinePrices(newBaselines);
      lastUpdateRef.current = Date.now();
      setLoading(false);
    } catch (err) {
      console.error('Failed to update baseline prices:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    let updateInterval;

    const initializePyth = async () => {
      try {
        const connection = new PriceServiceConnection(
          'https://hermes.pyth.network',
          {
            logger: {
              error: console.error,
              warn: console.warn,
              info: console.info,
              debug: console.debug
            }
          }
        );
        connectionRef.current = connection;
        
        if (mounted) {
          await updateBaseline();
          
          // Set up 24-hour updates
          updateInterval = setInterval(() => {
            if (Date.now() - lastUpdateRef.current >= 24 * 60 * 60 * 1000) {
              updateBaseline();
            }
          }, 60000); // Check every minute
        }
      } catch (err) {
        if (mounted) {
          console.error('Pyth initialization failed:', err);
          setError(err.message);
          setLoading(false);
        }
      }
    };

    initializePyth();

    return () => {
      mounted = false;
      if (updateInterval) {
        clearInterval(updateInterval);
      }
      if (connectionRef.current) {
        connectionRef.current.closeWebSocket();
      }
    };
  }, []);

  return {
    baselinePrices,
    loading,
    error,
    calculateDailyPNL,
    forceUpdate: updateBaseline
  };
};