import { createContext, useContext, useEffect, useState } from 'react';
import { usePythPrices } from '../hooks/usePythPrices';
import IconWalet from '../assets/images/IconWallet.png';
import BTC from '../assets/images/IconBTC.png';
import SOL from '../assets/images/IconSOL.png';
import ETH from '../assets/images/IconETH.png';
import VectorIcon from '../assets/images/Vector.png';
import VectorIcon2 from '../assets/images/Vector2.png';
import VectorIcon3 from '../assets/images/Vector3.png';

export const PriceContext = createContext({
  prices: {},
  loading: true,
  error: null
});

export const PriceProvider = ({ children }) => {
  const pythData = usePythPrices();
  return (
    <PriceContext.Provider value={pythData}>
      {children}
    </PriceContext.Provider>
  );
};

export const usePriceData = () => {
  const { prices, loading, error } = useContext(PriceContext);
  const [currentRateData, setCurrentRateData] = useState([]);
  const [cardDataState, setCardDataState] = useState([]);
  const [balanceDataState, setBalanceDataState] = useState([]);

  useEffect(() => {
    if (!prices) return;

    // Update current rate data
    const updatedCurrentRate = [
      {
        currency: 'BTCUSDT',
        digital_currency: 'Bitcoin',
        amount: prices.BTCUSDT?.price || '$0.00',
        commission: prices.BTCUSDT?.commission || '0%',
        category: 'defi'
      },
      {
        currency: 'ETHUSDT',
        digital_currency: 'Ethereum',
        amount: prices.ETHUSDT?.price || '$0.00',
        commission: prices.ETHUSDT?.commission || '0%',
        category: 'defi'
      },
      {
        currency: 'SOLUSDT',
        digital_currency: 'Solana',
        amount: prices.SOLUSDT?.price || '$0.00',
        commission: prices.SOLUSDT?.commission || '0%',
        category: 'defi'
      }
    ];

    // Update card data
    const updatedCardData = [
      {
        id: 1,
        icon: BTC,
        vector: VectorIcon2,
        name: "BTCUSDT",
        description: "Bitcoin",
        price: prices.BTCUSDT?.price || "$0.00",
        pnlDaily: {
          value: calculatePnlValue(prices.BTCUSDT?.price),
          percentage: prices.BTCUSDT?.commission || "0%",
        },
      },
      {
        id: 2,
        icon: ETH,
        vector: VectorIcon,
        name: "ETHUSDT",
        description: "Ethereum",
        price: prices.ETHUSDT?.price || "$0.00",
        pnlDaily: {
          value: calculatePnlValue(prices.ETHUSDT?.price),
          percentage: prices.ETHUSDT?.commission || "0%",
        },
      },
      {
        id: 3,
        icon: SOL,
        vector: VectorIcon3,
        name: "SOLUSDT",
        description: "Solana",
        price: prices.SOLUSDT?.price || "$0.00",
        pnlDaily: {
          value: calculatePnlValue(prices.SOLUSDT?.price),
          percentage: prices.SOLUSDT?.commission || "0%",
        },
      }
    ];

    // Update balance data with loading state
    const btcPrice = parseFloat(prices.BTCUSDT?.price?.replace(/[$,]/g, '') || '0');
    const updatedBalanceData = [{
      id: 1,
      icon: IconWalet,
      name: "Estimated Balance",
      description: "Bitcoin",
      balance: loading ? "Loading..." : `$${(btcPrice * 0.1).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`,
      mntProfit: {
        value: loading ? "..." : calculatePnlValue(prices.BTCUSDT?.price),
        percentage: loading ? "..." : prices.BTCUSDT?.commission || "0%",
      },
    }];

    setCurrentRateData(updatedCurrentRate);
    setCardDataState(updatedCardData);
    setBalanceDataState(updatedBalanceData);
  }, [prices, loading]);

  return {
    currentRateData,
    cardData: cardDataState,
    BalanceData: balanceDataState,
    loading,
    error
  };
};

const calculatePnlValue = (price) => {
  if (!price) return "$0.00";
  const numericPrice = parseFloat(price.replace(/[$,]/g, ''));
  const pnl = (numericPrice * 0.01).toFixed(2);
  return (pnl >= 0 ? '+' : '') + `$${Math.abs(pnl)}`;
};