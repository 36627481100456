import { useState, useEffect, useRef } from 'react';
import { PriceServiceConnection } from '@pythnetwork/price-service-client';

const PRICE_IDS = {
 'BTCUSDT': '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
 'ETHUSDT': '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
 'SOLUSDT': '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d'
};

export const usePythPrices = () => {
 const [prices, setPrices] = useState({});
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);
 const connectionRef = useRef(null);

 useEffect(() => {
   let mounted = true;
   console.log('Starting price feed with IDs:', PRICE_IDS);

   const updatePrice = (priceFeed) => {
     if (!mounted) return;
     
     try {
       const symbol = Object.entries(PRICE_IDS).find(([_, id]) => 
         id.indexOf(priceFeed.id) !== -1
       )?.[0];

       if (!symbol || !priceFeed.price) return;

       const price = Number(priceFeed.price.price) * Math.pow(10, priceFeed.price.expo);

       console.log(`Updating ${symbol} price to:`, price);

       setPrices(prev => ({
         ...prev,
         [symbol]: {
           price: `$${price.toLocaleString(undefined, {
             minimumFractionDigits: 2,
             maximumFractionDigits: 2
           })}`,
           commission: `${calculatePriceChange(price, prev[symbol]?.price)}%`
         }
       }));
     } catch (err) {
       console.error('Price update error:', err);
     }
   };

   const calculatePriceChange = (newPrice, oldPriceStr) => {
     if (!oldPriceStr) return '0.00';
     const oldPrice = parseFloat(oldPriceStr.replace(/[$,]/g, ''));
     const change = ((newPrice - oldPrice) / oldPrice * 100).toFixed(2);
     return change >= 0 ? `+${change}` : change;
   };

   const initializePyth = async () => {
     try {
       const connection = new PriceServiceConnection(
         'https://hermes.pyth.network', 
         {
           logger: { 
             error: console.error,
             warn: console.warn,
             info: console.info,
             debug: console.debug
           }
         }
       );
       connectionRef.current = connection;
       
       const priceIds = Object.values(PRICE_IDS);
       console.log('Full price feeds:', JSON.stringify(await connection.getLatestPriceFeeds(priceIds), null, 2));
       
       if (mounted) {
         const priceFeeds = await connection.getLatestPriceFeeds(priceIds);
         priceFeeds.forEach(updatePrice);
         await connection.subscribePriceFeedUpdates(priceIds, updatePrice);
         setLoading(false);
       }
     } catch (err) {
       if (mounted) {
         console.error('Pyth initialization failed:', err);
         setError(err.message);
         setLoading(false);
       }
     }
   };

   initializePyth();

   return () => {
     mounted = false;
     if (connectionRef.current) {
       connectionRef.current.closeWebSocket();
     }
   };
 }, []);

 return { prices, loading, error };
};