import React, { useState } from 'react';
import { AuthLayout } from '../components/AuthLayout';
import { supabase } from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';

export default function SignUp({ onSignUp }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  async function createUserProfile(userId, email, username) {
    const { error: profileError } = await supabase
      .from('profiles')
      .insert([
        {
          id: userId,
          email,
          username,
          is_email_verified: false,
        }
      ]);

    if (profileError) throw profileError;

    const { error: settingsError } = await supabase
      .from('settings')
      .insert([
        {
          id: userId,
          dark_mode: false,
          email_notifications: true,
          push_notifications: true,
          language: 'en',
          two_factor_auth: false,
        }
      ]);

    if (settingsError) throw settingsError;
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
  
    const formData = new FormData(event.target);
    const email = formData.get('email');
    const password = formData.get('password');
    const username = formData.get('name');
    
    let authData; // Declare authData outside try block
  
    try {
      // Sign up with Supabase
      const { data, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username,
          },
        },
      });
  
      if (authError) throw authError;
      
      authData = data; // Assign the response data
  
      // Create profile and settings
      await createUserProfile(authData.user.id, email, username);
  
      // Call the onSignUp prop to update auth state in App.js
      onSignUp();
  
      // Navigate to dashboard
      navigate('/');
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message || 'An error occurred during sign up');
      
      // If profile creation fails and we have an authData, attempt to delete the auth user
      if (error.message.includes('profiles') && authData?.user?.id) {
        await supabase.auth.admin.deleteUser(authData.user.id);
        setError('Failed to create user profile. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <AuthLayout
      title="Create an account"
      description="Sign up to get started"
      showSignInLink
    >
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="text-sm text-red-700">{error}</div>
          </div>
        )}

        <div className="-space-y-px rounded-md shadow-sm">
          <div>
            <label htmlFor="name" className="sr-only">
              Username
            </label>
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Username"
            />
          </div>
          <div>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="relative block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Email address"
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              required
              minLength="6"
              className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Password (min. 6 characters)"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={isLoading}
            className="group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? 'Signing up...' : 'Sign up'}
          </button>
        </div>
      </form>
    </AuthLayout>
  );
}