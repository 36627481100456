import React from 'react';
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/images/company-logo.png';  // Import your logo

export function AuthLayout({ children, title, description, showSignInLink, showSignUpLink }) {
  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      <div className="flex-1 flex items-center justify-center px-6 py-12 lg:px-8">
        <div className="w-full max-w-sm space-y-8">
          <div>
            <img
              className="mx-auto h-0 w-auto object-contain"
              src={CompanyLogo}  // Use the imported logo
              alt="Company Logo"
            />
            <h2 className="mt-0 text-center text-3xl font-bold tracking-tight text-gray-900">
              {title}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {description}
            </p>
          </div>
          {children}
          {showSignInLink && (
            <p className="mt-2 text-center text-sm text-gray-600">
              Already have an account?{" "}
              <Link to="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                Sign in
              </Link>
            </p>
          )}
          {showSignUpLink && (
            <p className="mt-2 text-center text-sm text-gray-600">
              Don't have an account?{" "}
              <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                Sign up
              </Link>
            </p>
          )}
        </div>
      </div>
      <div className="hidden lg:block lg:flex-1 bg-indigo-600 relative">
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/20 via-transparent to-pink-500/20" />
      </div>
    </div>
  );
}