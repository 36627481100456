import { useState, useEffect } from 'react';

const WALLET_ADDRESS = 'bc1q5xs7d2nqdrknw9qmauc6jr2rtn2nemm4xkcuyn';

export const useWalletBalance = () => {
  const [walletData, setWalletData] = useState({
    balance: 0,
    totalReceived: 0,
    totalSent: 0,
    numberOfTransactions: 0,
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        // Using the balance endpoint which has CORS enabled
        const response = await fetch(`https://blockchain.info/balance?active=${WALLET_ADDRESS}&cors=true`);
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const data = await response.json();
        
        // blockchain.info returns balance in satoshis, convert to BTC
        const balanceInBTC = data[WALLET_ADDRESS].final_balance / 100000000;
        
        setWalletData({
          balance: balanceInBTC,
          totalReceived: data[WALLET_ADDRESS].total_received / 100000000,
          totalSent: (data[WALLET_ADDRESS].total_received - data[WALLET_ADDRESS].final_balance) / 100000000,
          numberOfTransactions: data[WALLET_ADDRESS].n_tx || 0,
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('Error fetching wallet data:', error);
        setWalletData(prev => ({
          ...prev,
          loading: false,
          error: 'Failed to fetch wallet data'
        }));
      }
    };

    fetchWalletData();
    const interval = setInterval(fetchWalletData, 300000); // 5 minutes

    return () => clearInterval(interval);
  }, []);

  return walletData;
};