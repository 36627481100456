import React from 'react';
import { useNavigate } from 'react-router-dom';

function Button() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/transactions');
  };

  return (
    <button 
      onClick={handleClick}
      className='text-[#8c8c8c] text-[10px] font-semibold leading-[22px] border border-[#E5E5E5] dark:border-[#303036] dark:bg-[#24242B] rounded-[7px] px-2.5 py-0.5 inline-block hover:bg-[#f5f5f5] dark:hover:bg-[#303036] transition-colors'
    >
      View All
    </button>
  );
}

export default Button;